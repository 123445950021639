import React, { useState, useEffect } from 'react';
import Layout from '@accrosoft-ltd/accropress-theme-durham/src/components/layout';
import { Helmet } from 'react-helmet';

import 'url-search-params-polyfill';

import SEO from '@accrosoft-ltd/accropress-websites-components/dist/esm/seo';

import { returnResizedImageURL } from '@accrosoft-ltd/accropress-websites-components/dist/esm/imgUtils';
import '@accrosoft-ltd/accropress-theme-durham/src/components/wig-bundle.scss';

import gatsbyConfig from '@accrosoft-ltd/accropress-theme-durham/theme-config';

import dayjs from 'dayjs';

import loadable from '@loadable/component';

const WigLoader = loadable(() =>
  import('@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader')
);
const MapWidget = loadable(() =>
  import('@accrosoft-ltd/accropress-websites-components/dist/esm/mapWidget')
);

require('es6-promise').polyfill();

const VacancyDetailsPage = ({
  pageContext: { pluginOptions, contentDataData },
  props,
  location,
}) => {
  const config = pluginOptions;

  const [loading, setLoading] = useState(true);
  const [vacancy, setVacancy] = useState({});

  const AccroPressProdEndpoint = config.apiEndpoint;
  const AccroPressLocalEndpoint = config.apiEndpoint;
  const integratorID = config.integratorID;
  const integratorAccessToken = config.integratorAccessToken;
  const contentTypeID = config.contentTypeID;

  useEffect(() => {
    setLoading(true);
    if (location && location.search) {
      let searchParams = new URLSearchParams(location.search);
      let entries = searchParams.entries();

      let vacancyID = null;

      for (let pair of entries) {
        if (pair[0] === 'vacancyID') {
          vacancyID = pair[1];
        }
      }

      if (vacancyID) {
        return fetch(
          `${AccroPressProdEndpoint}integrations/content/single/${integratorID}/${integratorAccessToken}/${vacancyID}`
        )
          .then((response) => response.json())
          .then(function (response) {
            setVacancy(response);
            setLoading(false);
          })
          .catch(function (err) {
            // There was an error
            console.warn('Something went wrong.', err);
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [location]);

  return (
    <Layout {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{loading ? 'Loading vacancy details...' : ''}</title>
        <meta
          name="description"
          content={loading ? 'Loading vacancy details...' : ''}
        />
      </Helmet>

      <div className="banner page">
        <div
          className="hero"
          style={{
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage: `
                url("${
                  contentDataData &&
                  contentDataData[0].children[0] &&
                  contentDataData[0].children[0].value
                    ? returnResizedImageURL(
                        { w: 1900 },
                        contentDataData[0].children[0].value
                      )
                    : 'https://cdn2.accropress.com/content-uploads/a88479a3-cb1b-45e5-8424-b54d7ebb5fef/1fbd2555-cc5e-4bc0-8122-63465ebb5299/cmD75bxaTC2mnfJA1NuO_dji_0038_jpg'
                }")`,
          }}
        ></div>
        <div className="brush-stoke-banner"></div>
      </div>

      <div className="mainContentWrapper page">
        <section className="app-content" id="vacancy">
          <div className="container">
            {console.log(vacancy)}
            {!loading && vacancy && vacancy.contentMeta ? (
              <>
                {vacancy.customFieldsJSON[0].children[13] &&
                vacancy.customFieldsJSON[0].children[13].value &&
                vacancy.customFieldsJSON &&
                vacancy.customFieldsJSON.length &&
                vacancy.customFieldsJSON.length > 0 &&
                vacancy.customFieldsJSON[0].children &&
                vacancy.customFieldsJSON[0].children.length &&
                vacancy.customFieldsJSON[0].children.length > 0 &&
                vacancy.customFieldsJSON[0].children[13].value >=
                  new Date().toISOString() ? (
                  <div className="row">
                    <SEO
                      defaultSiteData={gatsbyConfig.siteMetadata}
                      navResultData={{}}
                      contentDataData={vacancy.contentData}
                      integratorData={vacancy.integrator}
                      contentMetaData={vacancy.contentMeta}
                    />
                    <div className="col-12 col-8-l col-8-m pageMain">
                      <h1 className="entry-title">
                        {vacancy.customFieldsJSON &&
                        vacancy.customFieldsJSON[0] &&
                        vacancy.customFieldsJSON[0].children &&
                        vacancy.customFieldsJSON[0].children.find(
                          (element) => element.id === 'title'
                        )
                          ? vacancy.customFieldsJSON[0].children.find(
                              (element) => element.id === 'title'
                            ).value
                          : vacancy.contentMeta.title}
                      </h1>
                      <div className="job-description">
                        {vacancy.customFieldsJSON &&
                          vacancy.customFieldsJSON[0] &&
                          vacancy.customFieldsJSON[0].children &&
                          vacancy.customFieldsJSON[0].children.find(
                            (element) => element.id === 'description'
                          ) && (
                            <WigLoader
                              customDataArray={
                                vacancy.customFieldsJSON[0].children.find(
                                  (element) => element.id === 'description'
                                ).value
                              }
                              contentDataData={vacancy.customFieldsJSON}
                              contentMetaData={vacancy.contentMeta}
                              contentDataArray="0"
                              contentDataChild="2"
                              AccroPressProdEndpoint={config.apiEndpoint}
                              AccroPressLocalEndpoint={config.apiEndpoint}
                              integratorID={config.integratorID}
                              integratorAccessToken={
                                config.integratorAccessToken
                              }
                            />
                          )}
                      </div>
                      {vacancy.customFieldsJSON &&
                        vacancy.customFieldsJSON[0] &&
                        vacancy.customFieldsJSON[0].children &&
                        vacancy.customFieldsJSON[0].children.find(
                          (element) => element.id === 'attachments'
                        ) &&
                        vacancy.customFieldsJSON[0].children.find(
                          (element) => element.id === 'attachments'
                        ).children &&
                        vacancy.customFieldsJSON[0].children.find(
                          (element) => element.id === 'attachments'
                        ).children.length > 0 && (
                          <>
                            <strong>Job Attachments</strong>
                            <ul className="job-attachments">
                              {vacancy.customFieldsJSON[0].children
                                .find((element) => element.id === 'attachments')
                                .children.map((attachment, i) => (
                                  <li key={i}>
                                    <a
                                      className="job-attachment-link"
                                      href={attachment[0].value}
                                    >
                                      {attachment[0].text}
                                    </a>
                                  </li>
                                ))}
                            </ul>
                          </>
                        )}
                    </div>

                    <div className="col-12 col-4-l col-4-m">
                      <div className="sidebar">
                        <h2>Job Summary</h2>
                        <div className="wrapper">
                          {typeof window !== 'undefined' &&
                            vacancy.customFieldsJSON &&
                            vacancy.customFieldsJSON[0] &&
                            vacancy.customFieldsJSON[0].children &&
                            vacancy.customFieldsJSON[0].children.find(
                              (element) => element.id === 'map'
                            ) &&
                            vacancy.customFieldsJSON[0].children.find(
                              (element) => element.id === 'map'
                            ).value && (
                              <div className="map-wrapper">
                                <MapWidget
                                  latitude={Number(
                                    vacancy.customFieldsJSON[0].children
                                      .find((element) => element.id === 'map')
                                      .value.split(', ')[0]
                                  )}
                                  longitude={Number(
                                    vacancy.customFieldsJSON[0].children
                                      .find((element) => element.id === 'map')
                                      .value.split(', ')[1]
                                  )}
                                  center={[
                                    Number(vacancy.Latitude),
                                    Number(vacancy.Longitude),
                                  ]}
                                  zoom="12"
                                  pitch="0"
                                  bearing="0"
                                  scrollZoom="false"
                                  pitchWithRotate="false"
                                  dragRotate="false"
                                />
                              </div>
                            )}
                          <div className="short-details">
                            <span className="key">Location:</span>
                            <span className="value">
                              {vacancy.customFieldsJSON &&
                              vacancy.customFieldsJSON[0] &&
                              vacancy.customFieldsJSON[0].children &&
                              vacancy.customFieldsJSON[0].children.find(
                                (element) => element.id === 'location'
                              ) &&
                              vacancy.customFieldsJSON[0].children.find(
                                (element) => element.id === 'location'
                              ).value
                                ? vacancy.customFieldsJSON[0].children.find(
                                    (element) => element.id === 'location'
                                  ).value + ' - '
                                : ''}
                              {vacancy.customFieldsJSON &&
                              vacancy.customFieldsJSON[0] &&
                              vacancy.customFieldsJSON[0].children &&
                              vacancy.customFieldsJSON[0].children.find(
                                (element) => element.id === 'region'
                              ) &&
                              vacancy.customFieldsJSON[0].children.find(
                                (element) => element.id === 'region'
                              ).value
                                ? vacancy.customFieldsJSON[0].children.find(
                                    (element) => element.id === 'region'
                                  ).value + ' - '
                                : ''}
                              {vacancy.customFieldsJSON &&
                              vacancy.customFieldsJSON[0] &&
                              vacancy.customFieldsJSON[0].children &&
                              vacancy.customFieldsJSON[0].children.find(
                                (element) => element.id === 'postcode'
                              ) &&
                              vacancy.customFieldsJSON[0].children.find(
                                (element) => element.id === 'postcode'
                              ).value
                                ? vacancy.customFieldsJSON[0].children.find(
                                    (element) => element.id === 'postcode'
                                  ).value + ' - '
                                : ''}
                            </span>
                          </div>
                          {vacancy.customFieldsJSON &&
                            vacancy.customFieldsJSON[0] &&
                            vacancy.customFieldsJSON[0].children &&
                            vacancy.customFieldsJSON[0].children.find(
                              (element) => element.id === 'reference'
                            ) &&
                            vacancy.customFieldsJSON[0].children.find(
                              (element) => element.id === 'reference'
                            ).value && (
                              <div className="short-details">
                                <span className="key">Reference: </span>
                                <span className="value">
                                  {
                                    vacancy.customFieldsJSON[0].children.find(
                                      (element) => element.id === 'reference'
                                    ).value
                                  }
                                </span>
                              </div>
                            )}
                          {vacancy.customFieldsJSON &&
                            vacancy.customFieldsJSON[0] &&
                            vacancy.customFieldsJSON[0].children &&
                            vacancy.customFieldsJSON[0].children.find(
                              (element) => element.id === 'salary'
                            ) &&
                            vacancy.customFieldsJSON[0].children.find(
                              (element) => element.id === 'salary'
                            ).value && (
                              <div className="short-details">
                                <span className="key">Salary: </span>
                                <span className="value">
                                  {
                                    vacancy.customFieldsJSON[0].children.find(
                                      (element) => element.id === 'salary'
                                    ).value
                                  }
                                </span>
                              </div>
                            )}
                          {vacancy.customFieldsJSON &&
                            vacancy.customFieldsJSON[0] &&
                            vacancy.customFieldsJSON[0].children &&
                            vacancy.customFieldsJSON[0].children.find(
                              (element) => element.id === 'category'
                            ) &&
                            vacancy.customFieldsJSON[0].children.find(
                              (element) => element.id === 'category'
                            ).value && (
                              <div className="short-details">
                                <span className="key">Category: </span>
                                <span className="value">
                                  {
                                    vacancy.customFieldsJSON[0].children.find(
                                      (element) => element.id === 'category'
                                    ).value
                                  }
                                </span>
                              </div>
                            )}
                          {vacancy.customFieldsJSON &&
                            vacancy.customFieldsJSON[0] &&
                            vacancy.customFieldsJSON[0].children &&
                            vacancy.customFieldsJSON[0].children.find(
                              (element) => element.id === 'type-and-time'
                            ) &&
                            vacancy.customFieldsJSON[0].children.find(
                              (element) => element.id === 'type-and-time'
                            ).value && (
                              <div className="short-details">
                                <span className="key">Job Type and Time:</span>
                                <span className="value">
                                  {
                                    vacancy.customFieldsJSON[0].children.find(
                                      (element) =>
                                        element.id === 'type-and-time'
                                    ).value
                                  }
                                </span>
                              </div>
                            )}

                          {vacancy.customFieldsJSON &&
                            vacancy.customFieldsJSON[0] &&
                            vacancy.customFieldsJSON[0].children &&
                            vacancy.customFieldsJSON[0].children.find(
                              (element) => element.id === 'posted-on'
                            ) &&
                            vacancy.customFieldsJSON[0].children.find(
                              (element) => element.id === 'posted-on'
                            ).value && (
                              <div className="short-details">
                                <span className="key">Posted on:</span>
                                <span className="value">
                                  {dayjs(
                                    vacancy.customFieldsJSON[0].children.find(
                                      (element) => element.id === 'posted-on'
                                    ).value
                                  ).format('DD/MM/YYYY')}
                                </span>
                              </div>
                            )}
                          {vacancy.customFieldsJSON &&
                            vacancy.customFieldsJSON[0] &&
                            vacancy.customFieldsJSON[0].children &&
                            vacancy.customFieldsJSON[0].children.find(
                              (element) => element.id === 'start-date'
                            ) &&
                            vacancy.customFieldsJSON[0].children.find(
                              (element) => element.id === 'start-date'
                            ).value && (
                              <div className="short-details">
                                <span className="key">Start date:</span>
                                <span className="value">
                                  {dayjs(
                                    vacancy.customFieldsJSON[0].children.find(
                                      (element) => element.id === 'start-date'
                                    ).value
                                  ).format('DD/MM/YYYY')}
                                </span>
                              </div>
                            )}
                          {vacancy.customFieldsJSON &&
                            vacancy.customFieldsJSON[0] &&
                            vacancy.customFieldsJSON[0].children &&
                            vacancy.customFieldsJSON[0].children.find(
                              (element) => element.id === 'closing-on'
                            ) &&
                            vacancy.customFieldsJSON[0].children.find(
                              (element) => element.id === 'closing-on'
                            ).value && (
                              <div className="short-details">
                                <span className="key">Closing on:</span>
                                <span className="value">
                                  {dayjs(
                                    vacancy.customFieldsJSON[0].children.find(
                                      (element) => element.id === 'closing-on'
                                    ).value
                                  ).format('DD/MM/YYYY')}
                                </span>
                              </div>
                            )}
                          {vacancy.customFieldsJSON &&
                            vacancy.customFieldsJSON[0] &&
                            vacancy.customFieldsJSON[0].children &&
                            vacancy.customFieldsJSON[0].children.find(
                              (element) => element.id === 'application-link'
                            ) &&
                            vacancy.customFieldsJSON[0].children.find(
                              (element) => element.id === 'application-link'
                            ).value && (
                              <div className="short-details short-details-actions">
                                <a
                                  className="btn btn-primary btn-apply"
                                  href={
                                    vacancy.customFieldsJSON[0].children.find(
                                      (element) =>
                                        element.id === 'application-link'
                                    ).value
                                  }
                                >
                                  {
                                    vacancy.customFieldsJSON[0].children.find(
                                      (element) =>
                                        element.id === 'application-link'
                                    ).text
                                  }
                                </a>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <h1>
                    A vacancy could not be found. Please return to the vacancy
                    list page.
                  </h1>
                )}
              </>
            ) : loading ? (
              <h1>Please wait while we load the details for this vacancy</h1>
            ) : (
              <h1>
                A vacancy could not be found. Please return to the vacancy list
                page.
              </h1>
            )}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default VacancyDetailsPage;
